import { Confirmation, Stage } from '.';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import {
    getBills,
    makeBillPayment,
    makeBillPaymentPresentment,
} from '@etica-js/api/src/appState/actions';
import { useFeatures } from '@etica-js/api/src/appState/hooks';
import { useAppContext } from '@etica-js/api/src/appState/state';
import { Bill, SavedBill } from '@etica-js/api/src/schema';

import form from '../../assets/styles.module.scss';
import { Aside, AsideProps } from '../ui/aside';
import { LoadingAnimation } from '../ui/loading';
import { TabSelect } from '../ui/tabSelect';
import { currencyFormat } from '../widgets/formatting';
import {
    DisplayBill,
    SaveNewBill,
    SavedBillsManager,
} from './parts/savedBills';
import { TokenSelector } from './parts/token';

type Props = {
    onClose: AsideProps['onClose'];
};

export const PayBill: React.FC<Props> = (props) => {
    const appCtx = useAppContext();
    const [amount, setAmount] = useState(0);
    const [accountNumber, setAccountNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const [selectedBill, setSelectedBill] = useState('');
    const [billAccount, setBillAccount] = useState('');
    const [billers, setBillers] = useState<ReadonlyArray<Bill>>([]);
    const [stage, setStage] = useState<Stage>(undefined);
    const [tokenMethod, setTokenMethod] = useState('sms');
    const [token, setToken] = useState('');
    const [billType, setBillType] = useState('');
    const [paybillNo, setPaybillNo] = useState('');

    const [selectedSavedBill, setSelectedSavedBill] = useState<SavedBill>();

    const showForm = useMemo(() => {
        if (billType === 'saved') {
            return !!selectedSavedBill;
        }
        return true;
    }, [selectedSavedBill, billType]);

    const features = useFeatures();

    const billTypes = useMemo(() => {
        let bills = [
            { value: 'mpesa_paybill', label: 'Mpesa Paybill' },
            { value: 'mpesa_till', label: 'Mpesa Buy Goods' },
            { value: 'utility', label: 'Utility bills' },
            { value: 'saved', label: 'Saved bills' },
        ];

        if (!features.mpesa_bills) {
            bills = bills.filter((b) => !b.value.startsWith('mpesa'));
        }

        if (!features.utility_bills) {
            bills = bills.filter((b) => b.value !== 'utility');
        }

        return bills;
    }, [features.mpesa_bills, features.utility_bills]);

    useEffect(() => {
        getBills().then((data) => {
            if (data.is_error) {
                toast.error(data.message);
                return;
            }
            setBillers(data.data ?? []);
        });
    }, []);

    const paybillLabel = useMemo(() => {
        if (billType === 'mpesa_till') {
            return 'Buy Goods Till Number';
        }
        return 'Paybill Number';
    }, [billType]);

    const getAccount = useCallback(() => {
        return appCtx.process('GET_ACCOUNTS');
    }, [appCtx]);

    useEffect(() => {
        if (billType === 'saved') {
            setSelectedBill('');
            setBillAccount('');
            setPaybillNo('');
        }

        if (billType.startsWith('mpesa')) {
            setSelectedBill('');
            setSelectedSavedBill(undefined);
        }

        if (billType === 'utility') {
            setSelectedSavedBill(undefined);
            setPaybillNo('');
        }
    }, [billType]);

    const getBillerName = useMemo(() => {
        switch (billType) {
            case 'mpesa_paybill':
                return `Mpesa Paybill ${paybillNo}`;
            case 'mpesa_till':
                return `Mpesa Till ${paybillNo}`;
            case 'utility':
                return (
                    billers.find((b) => b.code === selectedBill)?.name ?? 'None'
                );
            case 'saved':
                return (
                    `${selectedSavedBill?.provider_display} - ${selectedSavedBill?.name}` ??
                    'None'
                );
            default:
                return '';
        }
    }, [billType, billers, paybillNo, selectedBill, selectedSavedBill]);

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        if (stage === undefined) {
            makeBillPaymentPresentment({
                billType,
                bill_account: billAccount,
                bill: selectedBill,
                paybillNo,
                client_account: accountNumber,
                amount: amount,
                tokenMethod,
                saved_bill: selectedSavedBill?.id,
            })
                .then((result) => {
                    if (result.is_error) {
                        toast.error(result.message);
                        return;
                    }

                    const fees =
                        result.data?.fees.map((fee) => ({
                            label: fee.name,
                            value: currencyFormat({ value: fee.amount }),
                        })) ?? [];

                    setStage({
                        type: 'confirmation',
                        message: 'Please confirm your transaction below',
                        setToken,
                        tokenMethod,
                        data: [
                            {
                                label: 'Account',
                                value:
                                    getAccount()?.find(
                                        (acc) => acc.number === accountNumber
                                    )?.label ?? 'None',
                            },
                            {
                                label: 'Amount',
                                value: currencyFormat({ value: amount }),
                            },
                            {
                                label: 'Biller',
                                value: getBillerName,
                            },
                            {
                                label: 'Account Number',
                                value:
                                    billType === 'saved'
                                        ? selectedSavedBill?.account
                                        : billAccount,
                            },
                            ...fees,
                        ],
                    });
                })
                .finally(() => setLoading(false));

            return;
        }

        makeBillPayment({
            bill_account: billAccount,
            bill: selectedBill,
            client_account: accountNumber,
            amount: amount,
            token,
            billType,
            paybillNo,
            saved_bill: selectedSavedBill?.id,
        })
            .then((result) => {
                appCtx.logger?.logUserAction('pay_bill', !result.is_error);
                if (result.is_error) {
                    toast.error('Failed - ' + result.message);
                    return;
                }
                toast.success('Saved successfully');
                setComplete(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Aside
            onClose={props.onClose}
            success={complete}
            successComponent={
                <SaveNewBill
                    provider={billType}
                    bill_id={selectedBill}
                    bill_number={paybillNo}
                    account={billAccount}
                    savedBill={selectedSavedBill}
                />
            }
        >
            <div className="flex flex-wrap p-3">
                <h3 className="text-xl font-bold mb-5 mt-10">Pay Bills</h3>

                <form onSubmit={onSubmit} className="w-full">
                    {!stage && (
                        <div className="w-full mb-5">
                            <TabSelect
                                options={billTypes}
                                onChange={(opt) =>
                                    setBillType(opt?.value ?? '')
                                }
                            />
                        </div>
                    )}

                    {stage && stage.type === 'confirmation' && (
                        <Confirmation {...stage} />
                    )}
                    <div className="w-full">
                        {!showForm && (
                            <SavedBillsManager
                                onSelect={(bill) => setSelectedSavedBill(bill)}
                            />
                        )}
                        {showForm && (
                            <>
                                {!stage && (
                                    <>
                                        {billType === 'saved' &&
                                            selectedSavedBill && (
                                                <div className={form.fieldset}>
                                                    <DisplayBill
                                                        bill={selectedSavedBill}
                                                        onClear={() =>
                                                            setSelectedSavedBill(
                                                                undefined
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                        <div className={form.fieldset}>
                                            <label htmlFor="">
                                                Paying Account
                                            </label>
                                            <select
                                                onChange={(e) =>
                                                    setAccountNumber(
                                                        e.currentTarget.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="">
                                                    SELECT ACCOUNT
                                                </option>
                                                {getAccount().map((acc) => (
                                                    <option
                                                        key={acc.number}
                                                        value={acc.number}
                                                    >
                                                        {acc.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className={form.fieldset}>
                                            <label htmlFor="">Amount</label>
                                            <input
                                                type="number"
                                                step="0.01"
                                                onChange={(e) =>
                                                    setAmount(
                                                        parseFloat(
                                                            e.currentTarget
                                                                .value
                                                        )
                                                    )
                                                }
                                                required
                                            />
                                        </div>

                                        {billType === 'utility' && (
                                            <div className={form.fieldset}>
                                                <label htmlFor="">
                                                    Select Biller
                                                </label>
                                                <select
                                                    name="payment"
                                                    onChange={(e) =>
                                                        setSelectedBill(
                                                            e.currentTarget
                                                                .value
                                                        )
                                                    }
                                                    required
                                                >
                                                    <option value="">
                                                        SELECT BILLER
                                                    </option>
                                                    {billers.map((biller) => (
                                                        <option
                                                            key={biller.code}
                                                            value={biller.code}
                                                        >
                                                            {biller.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                        {billType.startsWith('mpesa') && (
                                            <div className={form.fieldset}>
                                                <label htmlFor="">
                                                    {paybillLabel}
                                                </label>
                                                <input
                                                    type="text"
                                                    onChange={(e) =>
                                                        setPaybillNo(
                                                            e.currentTarget
                                                                .value
                                                        )
                                                    }
                                                    required
                                                />
                                            </div>
                                        )}
                                        {billType !== 'saved' && (
                                            <div className={form.fieldset}>
                                                <label htmlFor="">
                                                    {billType === 'mpesa_till'
                                                        ? 'Account Number or Transaction Description'
                                                        : 'Account Number'}
                                                </label>
                                                <input
                                                    type="text"
                                                    onChange={(e) =>
                                                        setBillAccount(
                                                            e.currentTarget
                                                                .value
                                                        )
                                                    }
                                                    required
                                                />
                                            </div>
                                        )}
                                        <TokenSelector
                                            onTokenMethodChange={setTokenMethod}
                                        />
                                    </>
                                )}
                            </>
                        )}
                        <div className={form.fieldset}>
                            {stage && stage.type === 'confirmation' && (
                                <button
                                    className="secondary button w-full mb-2"
                                    onClick={() => setStage(undefined)}
                                >
                                    Back
                                </button>
                            )}
                            <button
                                type="submit"
                                className="primary button w-full"
                            >
                                <LoadingAnimation loading={loading} />{' '}
                                {stage ? 'Continue' : 'Send Token'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Aside>
    );
};
