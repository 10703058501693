import React from 'react';
import { Link } from 'react-router-dom';

import styles from '../../assets/styles.module.scss';
import { WithCover } from '../../layouts/covered';
import { TokenSelector } from '../actions/parts/token';
import { LoadingAnimation } from '../ui/loading';

type TextField = {
    type: 'text' | 'password' | 'number';
    placeholder?: string;
};

type SelectField = {
    type: 'select';
    choices: ReadonlyArray<{ value: string; label: string }>;
    placeholder?: string;
};

type Button = {
    type: 'button';
    onClick: (event: React.FormEvent<HTMLButtonElement>) => void;
    text: string | JSX.Element;
};

type TokenMethodField = {
    type: 'token_method';
    onChange: (token_method: string) => void;
    except?: ReadonlyArray<string>;
};

type Fields = TextField | SelectField | Button | TokenMethodField;

type Field = Fields & {
    id: string;
    label: string | null;
    onChange?: (
        event: React.FormEvent<HTMLInputElement | HTMLSelectElement>
    ) => void;
    hide?: boolean;
    required?: boolean;
};

type Props = {
    title: string;
    description: string;
    fields: ReadonlyArray<Field>;
    links: ReadonlyArray<{ text: string; location: string }>;
    submit?: {
        value: string;
        fn: (event: React.FormEvent) => void;
        loading?: boolean;
    };
    children?: JSX.Element;
};

export const LoginPane: React.FC<Props> = (props) => {
    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        props.submit?.fn(event);
    };

    const getField = (field: Field) => {
        switch (field.type) {
            case 'select':
                return (
                    <select
                        name={field.id}
                        onChange={field.onChange}
                        required={field.required}
                    >
                        {field.choices.map((choice) => (
                            <option key={choice.value} value={choice.value}>
                                {choice.label}
                            </option>
                        ))}
                    </select>
                );
            case 'button':
                return (
                    <button
                        className="w-full secondary button"
                        onClick={(e) => field.onClick(e)}
                    >
                        {field.text}
                    </button>
                );
            case 'token_method':
                return (
                    <TokenSelector
                        onTokenMethodChange={field.onChange}
                        label=""
                        className="w-full"
                        except={field.except}
                    />
                );
            default:
                return (
                    <input
                        type={field.type}
                        placeholder={field.placeholder}
                        onChange={field.onChange}
                        required={field.required}
                    />
                );
        }
    };
    return (
        <WithCover>
            <form onSubmit={onSubmit}>
                <h3 className="text-3xl font-bold">{props.title}</h3>
                <div className="mb-5">
                    <span className="text-base">{props.description}</span>
                </div>
                {props.fields.map(
                    (field) =>
                        !field.hide && (
                            <fieldset
                                className={styles.fieldset}
                                key={field.id}
                            >
                                {field.label && (
                                    <label
                                        className="flex w-full"
                                        htmlFor={field.id}
                                    >
                                        {field.label}
                                    </label>
                                )}
                                {getField(field)}
                            </fieldset>
                        )
                )}
                <>{props.children}</>
                {props.submit && (
                    <fieldset className={styles.fieldset}>
                        <button type="submit" className="primary button w-full">
                            <>
                                <LoadingAnimation
                                    loading={props.submit?.loading ?? false}
                                />
                                {props.submit?.value}
                            </>
                        </button>
                    </fieldset>
                )}
                <div className="flex flex-wrap">
                    {props.links.map((link) => (
                        <div className="flex-grow" key={link.location}>
                            <p className="text-center">
                                <Link to={link.location}>{link.text}</Link>
                            </p>
                        </div>
                    ))}
                </div>
            </form>
        </WithCover>
    );
};
