import {
    ArrowDownOnSquareIcon,
    ArrowLongLeftIcon,
} from '@heroicons/react/24/outline';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { fetchStatement } from '@etica-js/api/src/appState/actions';
import { useAppContext } from '@etica-js/api/src/appState/state';
import { download } from '@etica-js/utils/src/download';

import form from '../../assets/styles.module.scss';
import { DatePicker } from '../../components/ui/datepicker';
import { LoadingAnimation } from '../../components/ui/loading';
import { Currency } from '../../components/widgets/formatting';
import styles from './dashboard.module.scss';

export const InvestmentPage = () => {
    const appCtx = useAppContext();
    const [selectedAccount, setSelectedAccount] = useState('');
    const [statement, setStatement] = useState('');
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState(new Date());

    const getAccounts = useCallback(() => {
        return appCtx.process('GET_ACCOUNTS');
    }, [appCtx]);

    const viewStatement = (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        fetchStatement(selectedAccount, 'html', startDate, endDate)
            .then((resp) => {
                if (resp.is_error) {
                    toast.error(resp.message);
                    return;
                }
                setStatement((resp.data as string) ?? '');
            })
            .finally(() => setLoading(false));
    };

    const downloadStatement = (event: React.FormEvent) => {
        event.preventDefault();
        setDownloading(true);

        fetchStatement(selectedAccount, 'pdf', startDate, endDate)
            .then((resp) => {
                if (resp.is_error) {
                    toast.error(resp.message);
                    return;
                }
                toast.success('Downloaded!');
                const file_name =
                    resp.file_name ?? `Statement ${selectedAccount}.pdf`;
                download((resp.data as Blob) ?? new Blob(), file_name);
            })
            .finally(() => setDownloading(false));
    };

    return (
        <div className="flex flex-wrap overflow-scroll">
            <h3 className="font-bold text-xl mb-5">My Investments</h3>

            {!statement && (
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Fund</th>
                            <th>Account</th>
                            <th>Currency</th>
                            <th>Principal</th>
                            {/* <th>Gross Interest</th> */}
                            <th>Net Interest</th>
                            {/* <th>Withholding tax</th> */}
                            <th>Withdrawal</th>
                            <th>Investment Balance</th>
                            <th>Cash Balance</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getAccounts().map((acc) => (
                            <tr key={acc.number} className="text-sm">
                                <td>{acc.product.name}</td>
                                <td>
                                    {acc.name} {acc.number}
                                </td>
                                <td> {acc.product.currency.code}</td>
                                <td>
                                    <Currency value={acc.valuation.principal} />
                                </td>
                                {/* <td>
                                    <Currency
                                        value={acc.valuation.gross_interest}
                                    />
                                </td> */}
                                <td>
                                    <Currency
                                        value={acc.valuation.net_interest}
                                    />
                                </td>
                                {/* <td>
                                    <Currency
                                        value={acc.valuation.withholding_tax}
                                    />
                                </td> */}
                                <td>
                                    <Currency
                                        value={acc.valuation.withdrawal}
                                    />
                                </td>
                                <td>
                                    <Currency
                                        value={acc.valuation.market_value}
                                    />
                                </td>
                                <td>
                                    <Currency
                                        value={acc.valuation.wallet_balance}
                                    />
                                </td>
                                <td>
                                    <Currency
                                        value={
                                            acc.valuation.market_value +
                                            acc.valuation.wallet_balance
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <div className="flex flex-wrap w-full mt-5 bg-white rounded">
                <form onSubmit={viewStatement} className="w-full">
                    <div className={form.fieldset + ' flex'}>
                        <div className="w-full md:w-1/2 p-2">
                            <DatePicker
                                wrapperClassName="w-full"
                                placeholderText="Start Date"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                isClearable={true}
                            />
                        </div>
                        <div className="w-full md:w-1/2 p-2">
                            <DatePicker
                                wrapperClassName="w-full"
                                placeholderText="End Date"
                                selected={endDate}
                                // startDate={startDate}
                                onChange={(date) => setEndDate(date)}
                                isClearable={true}
                            />
                        </div>
                        <div className="w-full md:w-1/2 p-2">
                            <select
                                className=""
                                onChange={(e) =>
                                    setSelectedAccount(e.currentTarget.value)
                                }
                                required={true}
                            >
                                <option value="">Select Account</option>
                                {getAccounts().map((acc) => (
                                    <option key={acc.number} value={acc.number}>
                                        {acc.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="w-full md:w-1/2 p-2">
                            <button
                                type="submit"
                                className="primary button w-full"
                            >
                                <LoadingAnimation loading={loading} /> View
                                Statement
                            </button>
                        </div>
                    </div>
                </form>

                {statement && (
                    <>
                        <div className="w-full px-5 py-2 rounded">
                            <div
                                dangerouslySetInnerHTML={{ __html: statement }}
                                className="overflow-scroll"
                            ></div>
                        </div>
                        <div className="w-full pl-5 mb-5">
                            <button
                                onClick={() => setStatement('')}
                                className="small secondary button mr-2"
                            >
                                <ArrowLongLeftIcon className="w-5 inline" />{' '}
                                Back
                            </button>
                            <button
                                onClick={downloadStatement}
                                className="small secondary button"
                            >
                                <LoadingAnimation loading={downloading} />{' '}
                                <ArrowDownOnSquareIcon className="w-5 inline" />{' '}
                                Download
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
