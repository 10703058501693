import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { useAppUtils } from '@etica-js/api/src/appState/hooks';
import { post } from '@etica-js/api/src/framework/http';
import { PasswordResetResponse } from '@etica-js/api/src/schema';

import { LoginPane } from '../../components/auth/login';

type SMSResetProps = {
    username: string;
};

const SMSReset: React.FC<SMSResetProps> = ({ username }) => {
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [password_repeat, setPasswordRepeat] = useState('');

    const navigate = useNavigate();
    const { encrypt, can_encrypt } = useAppUtils();

    const sendToken = async () => {
        const resp = await post('auth/reset-password/sms', {
            data: {
                username,
                token,
                password: encrypt(password),
                password_repeat: encrypt(password_repeat),
                encrypted: can_encrypt,
            },
        });

        const data = await resp.json();

        if (!resp.ok) {
            toast.error(data.Message);
        }

        return data;
    };

    const onSubmit = () => {
        setLoading(true);

        sendToken()
            .then((data) => {
                if (data.success) {
                    toast.success(
                        'Password set successfully. You can now use it to login'
                    );

                    navigate('/auth/login');
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <LoginPane
            title="Create your new password"
            description={`An SMS has been sent to your phone number with your reset token. Your username is ${username}`}
            fields={[
                {
                    id: 'username',
                    type: 'number',
                    label: 'Reset token from SMS',
                    placeholder: 'Enter reset token',
                    onChange: (event) => {
                        setToken(event.currentTarget.value);
                    },
                    required: true,
                },
                {
                    id: 'password',
                    type: 'password',
                    label: 'Enter password. Must contain: uppercase, lowercase and number',
                    placeholder: 'Enter password',
                    onChange: (e) => setPassword(e.currentTarget.value),
                    required: true,
                },
                {
                    id: 'repeat_password',
                    type: 'password',
                    label: 'Repeat your password',
                    placeholder: 'Repeat password',
                    onChange: (e) => setPasswordRepeat(e.currentTarget.value),
                    required: true,
                },
            ]}
            links={[]}
            submit={{ value: 'NEXT', fn: onSubmit, loading }}
        />
    );
};

const EmailReset: React.FC<SMSResetProps> = ({ username }) => {
    const navigate = useNavigate();
    const onSubmit = () => {
        navigate('/auth/login');
    };
    return (
        <LoginPane
            title="Reset email sent"
            description={`Click the link sent to your email. Your username is ${username}`}
            fields={[]}
            links={[]}
            submit={{ value: 'Login', fn: onSubmit }}
        />
    );
};

export const ForgotPassword = () => {
    const [username, setUsername] = React.useState('');
    const [loading, setLoading] = useState(false);
    const [confirmedName, setConfirmedName] = useState('');
    const [resetMethod, setResetMethod] = useState('');

    const postRequest = async (): Promise<PasswordResetResponse> => {
        const resp = await post('auth/reset-password-request', {
            data: {
                username,
            },
        });

        return await resp.json();
    };

    const onSubmit = (event: React.FormEvent) => {
        setLoading(true);
        postRequest()
            .then((data) => {
                if (data.success) {
                    setConfirmedName(data.username ?? '');
                    setResetMethod(data.mode ?? '');
                } else {
                    toast.error(data.Message);
                }
            })
            .finally(() => setLoading(false));
    };

    if (resetMethod === 'sms') {
        return <SMSReset username={confirmedName} />;
    }

    if (resetMethod === 'email') {
        return <EmailReset username={confirmedName} />;
    }

    return (
        <LoginPane
            title="Need help with your password?"
            description="Enter your username, email address or phone number"
            fields={[
                {
                    id: 'username',
                    type: 'text',
                    label: 'Username/Email/Phone',
                    placeholder: 'Enter username, email or phone number',
                    onChange: (event) => {
                        setUsername(event.currentTarget.value);
                    },
                },
            ]}
            links={[]}
            submit={{ value: 'NEXT', fn: onSubmit, loading }}
        />
    );
};

export const ResetByLink = () => {
    const { username, token } = useParams();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [password_repeat, setPasswordRepeat] = useState('');
    const [tokenMethod, setTokenMethod] = useState('');
    const [isEnteringPassword, setIsEnteringPassword] = useState(true);
    const [otp, setOtp] = useState('');
    const { encrypt, can_encrypt } = useAppUtils();

    const navigate = useNavigate();

    const savePassword = async (step?: 'send_otp') => {
        const resp = await post('auth/reset-password/email', {
            data: {
                username: username ?? '',
                token: token ?? '',
                otp,
                tokenMethod,
                step: step ?? '',
                password: encrypt(password),
                password_repeat: encrypt(password_repeat),
                encrypted: can_encrypt,
            },
        });

        const data = await resp.json();

        if (!resp.ok) {
            toast.error(data.Message);
        }

        return data;
    };

    const onSubmit = () => {
        setLoading(true);

        if (isEnteringPassword) {
            savePassword('send_otp')
                .then((data) => {
                    if (data.success) {
                        toast.success(
                            `Please enter token from your ${tokenMethod}`
                        );

                        setIsEnteringPassword(false);
                    }
                })
                .finally(() => setLoading(false));
            return;
        }

        savePassword()
            .then((data) => {
                if (data.success) {
                    toast.success(
                        'Password set successfully. You can now use it to login'
                    );

                    navigate('/auth/login');
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <LoginPane
            title="Create your new password"
            description=""
            fields={[
                {
                    id: 'password',
                    type: 'password',
                    label: 'Enter password. Must contain: uppercase, lowercase and number',
                    placeholder: 'Enter password',
                    onChange: (e) => setPassword(e.currentTarget.value),
                    required: true,
                },
                {
                    id: 'repeat_password',
                    type: 'password',
                    label: 'Repeat your password',
                    placeholder: 'Repeat password',
                    onChange: (e) => setPasswordRepeat(e.currentTarget.value),
                    required: true,
                },
                {
                    id: 'token_method',
                    type: 'token_method',
                    label: 'Send Login Token Via',
                    except: ['email'],
                    onChange: (value) => {
                        setTokenMethod(value?.toString() ?? '');
                    },
                    hide: !isEnteringPassword,
                },
                {
                    id: 'token',
                    type: 'number',
                    label: 'Password Reset Token',
                    placeholder: `Enter 6-digit token from ${tokenMethod}`,
                    onChange: (event) => {
                        setOtp(event.currentTarget.value);
                    },
                    hide: isEnteringPassword,
                },
            ]}
            links={[]}
            submit={{
                value: isEnteringPassword ? 'Send Token' : 'Save Password',
                fn: onSubmit,
                loading,
            }}
        />
    );
};
