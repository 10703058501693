import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import {
    fetchCurrencies,
    requestToken,
} from '@etica-js/api/src/appState/actions';
import { get, post } from '@etica-js/api/src/framework/http';
import { Bank, Branch, Currency } from '@etica-js/api/src/schema';

import form from '../../../assets/styles.module.scss';
import { Confirmation, Stage } from '../../../components/actions';
import { TokenSelector } from '../../../components/actions/parts/token';
import { LoadingAnimation } from '../../../components/ui/loading';
import styles from '../dashboard.module.scss';

export const AddBank = () => {
    const { code } = useParams();
    const [banks, setBanks] = useState<Bank[]>([]);
    const [bankBranches, setBankBranches] = useState<Branch[]>([]);
    const [currencies, setCurrencies] = useState<readonly Currency[]>([]);

    const [loading, setLoading] = useState(false);

    const [selectedBank, setSelectedBank] = useState<string | undefined>(
        undefined
    );
    const [selectedBranch, setSelectedBranch] = useState<string | undefined>(
        undefined
    );
    const [selectedCurrency, setSelectedCurrency] = useState<
        string | undefined
    >(undefined);
    const [accountNumber, setAccountNumber] = useState('');
    const [accountName, setAccountName] = useState('');
    const [enterBranch, setEnterBranch] = useState(false);
    const [stage, setStage] = useState<Stage>(undefined);
    const [tokenMethod, setTokenMethod] = useState('sms');
    const [token, setToken] = useState('');

    const navigate = useNavigate();

    const submit = (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        if (!stage) {
            requestToken(tokenMethod)
                .then((resp: Response) => {
                    if (!resp.ok) {
                        toast.error('Could not send verification token');
                        return;
                    }

                    setStage({
                        type: 'confirmation',
                        message: 'Please confirm your new bank details below',
                        tokenMethod,
                        setToken,
                        data: [
                            {
                                label: isMobileMoney() ? 'Provider' : 'Bank',
                                value:
                                    banks.find(
                                        (b) => b.id?.toString() === selectedBank
                                    )?.name ?? 'None',
                            },
                            ...(isMobileMoney()
                                ? []
                                : [
                                      {
                                          label: 'Branch',
                                          value:
                                              bankBranches.find(
                                                  (b) =>
                                                      b.id?.toString() ===
                                                      selectedBranch
                                              )?.name ?? selectedBranch,
                                      },
                                      {
                                          label: 'Currency',
                                          value:
                                              currencies.find(
                                                  (c) =>
                                                      c.code ===
                                                      selectedCurrency
                                              )?.name ?? 'None',
                                      },
                                  ]),
                            { label: 'Account Name', value: accountName },
                            { label: 'Account Number', value: accountNumber },
                        ],
                    });
                })
                .finally(() => setLoading(false));
            return;
        }

        if (!code) {
            toast.error('Cannot submit bank details');
            setLoading(false);
            return;
        }
        const data = {
            code: code ?? '',
            bank_id: selectedBank ?? '',
            branch_id: selectedBranch ?? '',
            currency_code: selectedCurrency ?? '',
            account_name: accountName,
            account_number: accountNumber,
            token,
        };

        post('client-api/bank-accounts/create', { data })
            .then((resp) => {
                if (resp.ok) {
                    toast.success('Bank account saved successfully');
                    navigate('/dashboard/profile');
                    return;
                }

                resp.json().then((data) => {
                    toast.error(`An error occured - ${data?.Message}`);
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchBanks = async () => {
        const resp = await get('client-api/banks');

        const data = await resp.json();

        if (resp.ok) {
            setBanks(data);
            return;
        }
        toast.error('An error occurred while fetching banks');
    };

    const getCurrencies = async () => {
        const { is_error, data } = await fetchCurrencies();

        if (!is_error) {
            setCurrencies(data ?? []);
            return;
        }
        toast.error('An error occurred while fetching currencies');
    };

    const fetchBranches = async (bank_id: string | undefined) => {
        if (!bank_id) {
            return;
        }
        const resp = await get('client-api/bank-branches', {
            params: bank_id ? { bank_id: bank_id } : {},
        });

        const data = await resp.json();

        if (resp.ok) {
            setBankBranches(data);
            return;
        }
        toast.error('An error occurred while fetching banks');
    };

    useEffect(() => {
        fetchBanks();
        getCurrencies();
    }, []);

    useEffect(() => {
        fetchBranches(selectedBank);
    }, [selectedBank]);

    const isMobileMoney = useCallback(() => {
        const bank = banks.find((b) => b.id.toString() === selectedBank);

        return bank && ['MPESA', 'SASAPAY'].includes(bank.swift_code);
    }, [banks, selectedBank]);

    return (
        <div className="bg-white flex flex-wrap rounded px-2 py-2">
            <div className="w-full">
                <h3 className="text-lg font-semibold px-2 mb-5">
                    Add Bank/Mobile Money Account
                </h3>
                <form
                    onSubmit={submit}
                    className={`w-full flex flex-wrap ${styles.fullpageForm}`}
                >
                    {stage && stage.type === 'confirmation' && (
                        <Confirmation {...stage} />
                    )}
                    {!stage && (
                        <>
                            <div
                                className={
                                    form.fieldset + ' w-full md:w-1/2 px-2'
                                }
                            >
                                <label htmlFor="">
                                    Bank/Mobile Money Provider
                                </label>
                                <Select
                                    className={form.searchSelect}
                                    options={banks
                                        .sort((a, b) =>
                                            a.name
                                                .toLowerCase()
                                                .localeCompare(
                                                    b.name.toLowerCase()
                                                )
                                        )
                                        .map((b) => ({
                                            value: b.id,
                                            label: b.name,
                                        }))}
                                    placeholder="Select bank/mobile money provider"
                                    onChange={(e) =>
                                        setSelectedBank(
                                            e?.value.toString() ?? ''
                                        )
                                    }
                                ></Select>
                            </div>
                            {!isMobileMoney() && (
                                <>
                                    <div
                                        className={
                                            form.fieldset +
                                            ' w-full md:w-1/2 px-2'
                                        }
                                    >
                                        <label htmlFor="">Branch</label>
                                        {!!enterBranch && (
                                            <input
                                                type="text"
                                                onChange={(e) =>
                                                    setSelectedBranch(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                        )}
                                        {!enterBranch && (
                                            <div className="flex w-full">
                                                <Select
                                                    className={
                                                        form.searchSelect
                                                    }
                                                    onChange={(e) =>
                                                        setSelectedBranch(
                                                            e?.value.toString() ??
                                                                ''
                                                        )
                                                    }
                                                    options={bankBranches
                                                        .sort((a, b) =>
                                                            a.name
                                                                .toLowerCase()
                                                                .localeCompare(
                                                                    b.name.toLowerCase()
                                                                )
                                                        )
                                                        .map((br) => ({
                                                            label: br.name,
                                                            value: br.id,
                                                        }))}
                                                    placeholder="Select branch"
                                                ></Select>

                                                <button
                                                    onClick={(e) =>
                                                        setEnterBranch(true)
                                                    }
                                                    className="secondary button ml-5 w-10"
                                                >
                                                    +
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className={
                                            form.fieldset +
                                            ' w-full md:w-1/2 px-2'
                                        }
                                    >
                                        <label htmlFor="">Currency</label>
                                        <select
                                            required
                                            onChange={(e) =>
                                                setSelectedCurrency(
                                                    e.currentTarget.value
                                                )
                                            }
                                        >
                                            <option value="">
                                                Select currency
                                            </option>
                                            {currencies.map((curr) => (
                                                <option
                                                    key={curr.code}
                                                    value={curr.code}
                                                >
                                                    {curr.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </>
                            )}
                            <div
                                className={
                                    form.fieldset + ' w-full md:w-1/2 px-2'
                                }
                            >
                                <label htmlFor="">Account Name</label>
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setAccountName(e.currentTarget.value)
                                    }
                                    required
                                />
                            </div>
                            <div
                                className={
                                    form.fieldset + ' w-full md:w-1/2 px-2'
                                }
                            >
                                <label htmlFor="">
                                    {isMobileMoney()
                                        ? 'Phone Number'
                                        : 'Account Number'}
                                </label>
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setAccountNumber(e.currentTarget.value)
                                    }
                                    required
                                />
                            </div>
                            <TokenSelector
                                onTokenMethodChange={setTokenMethod}
                                className={
                                    form.fieldset + ' w-full md:w-1/2 px-2'
                                }
                            />
                        </>
                    )}
                    <div className={form.fieldset + ' w-full px-2'}>
                        {stage && stage.type === 'confirmation' && (
                            <button
                                className="secondary button mr-2"
                                onClick={() => setStage(undefined)}
                            >
                                Back
                            </button>
                        )}
                        <button type="submit" className="primary button">
                            <LoadingAnimation loading={loading} /> Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
