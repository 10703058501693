import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getOtpLink, requestToken } from '@etica-js/api/src/appState/actions';
import { useAppUtils } from '@etica-js/api/src/appState/hooks';

import form from '../../../assets/styles.module.scss';
import { Confirmation, Stage } from '../../../components/actions';
import { LoginPane } from '../../../components/auth/login';
import { LoadingAnimation } from '../../../components/ui/loading';
import styles from '../dashboard.module.scss';

const QRCodeContainer: React.FC<{ link: string }> = ({ link }) => {
    return (
        <div className="bg-white flex flex-wrap rounded px-2 py-2">
            <QRCode value={link} />
        </div>
    );
};

const ShowQRCode: React.FC<{ link: string; loggedIn: boolean }> = ({
    link,
    loggedIn,
}) => {
    const secret = useMemo(() => {
        const parsedUrl = new URL(link);
        return parsedUrl.searchParams.get('secret');
    }, [link]);

    return (
        <div className="flex flex-wrap">
            <label className="w-full text-gray-500 ">
                Scan the QR code below using your authenticator app <br />
            </label>
            <label className="w-full text-gray-500 text-sm mb-10">
                Examples include Google Authenticator, Twilio Authy and
                Microsoft Authenticator
            </label>
            <QRCodeContainer link={link} />
            <label className="w-full text-sm text-gray-500 mt-10">
                You can also copy the link below and paste it in your
                authenticator app to set up the authenticator app manually.
                <br />
                <div className="text-wrap text-sm bg-gray-100 px-2 py-2 rounded">
                    {link}
                </div>
                <br />
                You can also use the secret below to set up Google Authenticator
                using the set up key option.
                <br />
                {secret && (
                    <>
                        <div className="text-wrap text-sm bg-gray-100 px-2 py-2 rounded">
                            {secret}
                        </div>
                        <br />
                    </>
                )}
                <ExclamationTriangleIcon className="w-8 text-orange-400 inline" />{' '}
                Do not share this link, QR code or secret with anyone
            </label>
            {loggedIn && (
                <div className="flex flex-wrap w-full">
                    <Link
                        to="/dashboard"
                        className="secondary button mb-5 mt-5"
                    >
                        Back Home
                    </Link>
                </div>
            )}
        </div>
    );
};

export const TwoFactorLink = () => {
    const { token } = useParams();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const { encrypt, can_encrypt } = useAppUtils();
    const [link, setLink] = useState('');
    const navigate = useNavigate();

    const onSubmit = () => {
        if (link) {
            return navigate('/auth/login');
        }
        if (!username || !password) {
            toast.error('Please enter username and password');
            return;
        }

        if (!can_encrypt) {
            toast.error(
                'Could not send your details securely. Please try again'
            );
            return;
        }

        setLoading(true);

        getOtpLink({
            token: '',
            linkToken: token,
            username,
            password: encrypt(password),
            encrypted: can_encrypt,
        })
            .then((res) => {
                if (res.is_error || !res.data?.link) {
                    toast.error(res.message ?? 'Could not obtain QR code');
                    return;
                }

                setLink(res.data?.link ?? '');
            })
            .finally(() => setLoading(false));
    };

    return (
        <LoginPane
            title="Set up two-factor authentication"
            description={
                link
                    ? ''
                    : 'Enter your username and password below to confirm your identity'
            }
            fields={[
                {
                    id: 'username',
                    type: 'text',
                    label: 'Username or email',
                    placeholder: 'Enter username or email address',
                    onChange: (event) => {
                        setUsername(event.currentTarget.value);
                    },
                    hide: !!link,
                },
                {
                    id: 'password',
                    type: 'password',
                    label: 'Password',
                    placeholder: 'Enter password',
                    onChange: (event) => {
                        setPassword(event.currentTarget.value);
                    },
                    hide: !!link,
                },
            ]}
            submit={{
                value: link ? 'Go to Login' : 'View QR Code',
                fn: onSubmit,
                loading,
            }}
            links={[]}
        >
            {link ? <ShowQRCode link={link} loggedIn={false} /> : <></>}
        </LoginPane>
    );
};

export const TwoFactorToken = () => {
    return <TwoFactor />;
};

export const TwoFactor: React.FC = () => {
    const [loading, setLoading] = useState(false);

    const [stage, setStage] = useState<Stage>(undefined);
    const [token, setToken] = useState('');
    const [link, setLink] = useState('');

    const viewQrCode = () => {
        getOtpLink({ token })
            .then((res) => {
                if (res.is_error || !res.data?.link) {
                    toast.error(res.message ?? 'Could not obtain QR code');
                    return;
                }

                setLink(res.data?.link ?? '');
            })
            .finally(() => setLoading(false));
    };

    const submit = (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        if (stage) {
            return viewQrCode();
        }

        requestToken('sms', 'two factor setup')
            .then((resp: Response) => {
                if (!resp.ok) {
                    resp.json().then((result) => {
                        toast.error(result.message ?? 'Unknown error');
                    });
                    return;
                }

                setStage({
                    type: 'confirmation',
                    message:
                        'Please enter the token you received via SMS then click on "View QR Code" to continue. \nBy viewing the QR code, you will invalidate any two factor authentication apps previously set up.',
                    tokenMethod: 'sms',
                    setToken,
                    data: [],
                });
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className="bg-white flex flex-wrap rounded px-2 py-2">
            <div className="w-full">
                <h3 className="text-lg font-semibold px-2 mb-5">
                    Set up Two Factor Authentication App
                </h3>
                {!link && (
                    <form
                        onSubmit={submit}
                        className={`w-full flex flex-wrap ${styles.fullpageForm}`}
                    >
                        {stage && stage.type === 'confirmation' && (
                            <Confirmation {...stage} />
                        )}
                        {!stage && (
                            <label className="mb-5 text-gray-500">
                                Click on Send Token below to receive a token via
                                sms.
                            </label>
                        )}
                        <div className={form.fieldset + ' w-full'}>
                            <button type="submit" className="primary button">
                                <LoadingAnimation loading={loading} />{' '}
                                {stage ? 'View QR Code' : 'Send Token'}
                            </button>
                        </div>
                    </form>
                )}

                {link && <ShowQRCode link={link} loggedIn={true} />}
            </div>
        </div>
    );
};
