import { Outlet } from 'react-router-dom';

import { useAppContext } from '@etica-js/api/src/appState/state';

import { AccountSettings } from '../../components/actions/accountSettings';
import { Invest } from '../../components/actions/newAccount';
import { PayBill } from '../../components/actions/payBills';
import { SendMoney } from '../../components/actions/sendMoney';
import { Topup } from '../../components/actions/topup';
import { Transfer } from '../../components/actions/transfers';
import { Withdraw } from '../../components/actions/withdraw';
import { SideNav } from '../../components/navigation/sidenav';
import { ProfileWidget } from '../../components/widgets/profile';
import { useAppState } from '../../utils/appState';
import styles from './dashboard.module.scss';

export { DashboardPage } from './dashboard';
export { ProfilePage } from './profile';
export { AddBank } from './profiles/add_bank';
export { InvestmentPage } from './investments';
export { AddNextOfKin } from './profiles/add_next_of_kin';
export { KycPage } from './kyc';
export { refreshDocs } from '../../utils/appState';
export { TwoFactorLink, TwoFactorToken } from './profiles/two_factor';

export const DashboardIndex = () => {
    const appContext = useAppContext();

    const openModal = appContext.ui.openModal;

    useAppState();

    const setOpenModal = (payload: string) => {
        appContext.dispatch({
            type: 'SET_OPEN_MODAL',
            payload: { name: payload, target: '' },
        });
    };

    const closeModal = () => setOpenModal('');

    return (
        <div className={styles.container + ' flex flex-wrap'}>
            <SideNav />
            <div className={styles.bodyPane + ' md:w-3/4 lg:w-4/5 px-4 py-2'}>
                <ProfileWidget />
                <Outlet />
            </div>
            {openModal.name === 'invest' && <Invest onClose={closeModal} />}
            {openModal.name === 'topup' && <Topup onClose={closeModal} />}
            {openModal.name === 'withdraw' && <Withdraw onClose={closeModal} />}
            {openModal.name === 'pay_bill' && <PayBill onClose={closeModal} />}
            {openModal.name === 'send-money' && (
                <SendMoney onClose={closeModal} />
            )}
            {openModal.name === 'transfers' && (
                <Transfer onClose={closeModal} />
            )}
            {openModal.name === 'account_settings' && (
                <AccountSettings onClose={closeModal}></AccountSettings>
            )}
        </div>
    );
};
