import { getFileNameFromResponse } from '@etica-js/utils/src/download';
import { dateFormatIso } from '@etica-js/utils/src/formatting';
import { debounce } from '@etica-js/utils/src/functional';

import { get, post, request } from '../framework/http';
import { setItem } from '../framework/storage';
import {
    ApplicationKYCData,
    Bill,
    Client,
    ClientBankAccount,
    Currency,
    Feature,
    InvestmentState,
    KycDocument,
    KycStatus,
    NextOfKin,
    Product,
    SavedBill,
    Settings,
} from '../schema';
import { AppStateDispatch } from './state';

type StandardResponse<T> = {
    is_error: boolean;
    message: string;
    data?: T;
};

type FeesResponse =
    | { fees: ReadonlyArray<{ name: string; amount: number }> }
    | undefined;

export const fetchInvestments = async (
    dispatch: AppStateDispatch
): Promise<InvestmentState | undefined> => {
    dispatch({
        type: 'SET_LOADING_STATE',
        payload: 'loading',
    });
    const resp = await get('client-api/investments').catch((err) => {
        dispatch({
            type: 'SET_LOADING_STATE',
            payload: 'error',
        });
        throw err;
    });

    if (resp.ok) {
        const data = await resp.json();

        dispatch({
            type: 'SET_INVESTMENT',
            payload: data,
        });

        return undefined;
    }

    dispatch({
        type: 'SET_LOADING_STATE',
        payload: 'error',
    });

    return undefined;
};

export const fetchKycStatus = async (
    dispatch?: AppStateDispatch
): Promise<KycStatus | undefined> => {
    const resp = await get('client-api/kyc-status?version=1');

    if (resp.ok) {
        const kyc = await resp.json();

        dispatch?.({ type: 'SET_KYC_STATUS', payload: kyc });

        return kyc;
    }

    return undefined;
};
export type KycData = {
    client_code: string;
    slug: string;
    file_name: string;
    joint_id?: string | number;
};

export const KYC_UPLOAD_URL = 'client-api/kyc-upload';

export const uploadKycDocs = async (
    files: File[],
    extraData: KycData
): Promise<{ success: boolean; error?: string }> => {
    const data = new FormData();

    files.forEach((file) => {
        data.append('file', file, file.name);
    });

    data.append('client_code', extraData.client_code);
    data.append('slug', extraData.slug);
    data.append('doc_name', extraData.file_name);
    data.append('joint_id', extraData.joint_id?.toString() ?? '');

    const resp = await post(KYC_UPLOAD_URL, {
        data,
        content: 'multipart',
    });

    const json = await resp.json();

    if (!resp.ok) {
        return { success: false, error: json?.Message };
    }

    return { success: true };
};

export const uploadApplicationKycDocs = async (
    code: string,
    signature: string,
    files: File[],
    extraData: KycData
): Promise<{ success: boolean; error?: string }> => {
    const data = new FormData();

    files.forEach((file) => {
        data.append('file', file, file.name);
    });

    data.append('client_code', extraData.client_code);
    data.append('slug', extraData.slug);
    data.append('doc_name', extraData.file_name);
    data.append('joint_id', extraData.joint_id?.toString() ?? '');

    const resp = await post(
        `clients/applications/${code}/${signature}/upload-kyc`,
        {
            data,
            content: 'multipart',
        }
    );

    const json = await resp.json();

    if (!resp.ok) {
        return { success: false, error: json?.Message };
    }

    return { success: true };
};

export const fetchClientBankAccounts = async (
    code?: string
): Promise<ClientBankAccount[]> => {
    if (!code) {
        return [];
    }
    const resp = await post('client-api/bank-accounts', {
        data: {
            code,
        },
    });

    if (resp.ok) {
        return await resp.json();
    }

    return [];
};

type TopupPresentmentArgs = {
    amount: number;
    number: string;
    payment_method: string;
    phoneNumber?: string;
    currency?: string;
};

export const createTopupPresentment = async ({
    amount,
    number,
    payment_method,
    phoneNumber,
    currency,
}: TopupPresentmentArgs) => {
    const resp = await post('client-api/presentment/topup', {
        data: {
            number,
            amount,
            payment_method,
            phone: phoneNumber ?? '',
            currency: currency ?? '',
        },
    });

    const data = await resp.json();
    if (resp.ok) {
        return data;
    }

    return {
        is_error: true,
        status_code: resp.status,
        message: data?.Message ?? 'Unknown error',
    };
};

type TopupArgs = {
    amount: number;
    number: string;
    payment_method: string;
    phoneNumber?: string;
    transaction_id?: string;
    token?: string;
    currency?: string;
};

export const createTopup = async ({
    amount,
    number,
    payment_method,
    phoneNumber,
    transaction_id,
    token,
    currency,
}: TopupArgs) => {
    const resp = await post('client-api/investments/topup', {
        data: {
            number,
            amount,
            payment_method,
            phone: phoneNumber ?? '',
            transaction_id: transaction_id ?? '',
            token: token ?? '',
            currency: currency ?? '',
        },
    });

    const data = await resp.json();
    if (resp.ok) {
        return data;
    }

    return {
        is_error: true,
        status_code: resp.status,
        message: data?.Message ?? 'Unknown error',
    };
};

type AccountSettingsArgs = {
    lock_in_period?: string;
    alias?: string;
    account: string;
};
type AccountSettingsResponse = {
    success: boolean;
    messages: ReadonlyArray<string>;
};
export const saveAccountSettings = async (
    args: AccountSettingsArgs
): Promise<StandardResponse<AccountSettingsResponse>> => {
    const resp = await post('client-api/customize-account', { data: args });

    const data = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: 'Success',
            data,
        };
    }

    return {
        is_error: true,
        message: data?.Message ?? `Unknown error ${resp.status}`,
    };
};

export const makeBillPaymentPresentment = async (params: {
    billType: string;
    client_account: string;
    bill: string;
    amount: number;
    bill_account: string;
    tokenMethod: string;
    paybillNo?: string;
    saved_bill?: number;
}): Promise<StandardResponse<FeesResponse>> => {
    const resp = await post('client-api/presentment/paybill', {
        data: {
            bill_type: params.billType,
            number: params.client_account,
            bill: params.bill,
            amount: params.amount,
            bill_account: params.bill_account,
            token_method: params.tokenMethod,
            paybill_number: params.paybillNo ?? '',
            saved_bill: params.saved_bill ?? '',
        },
    });

    const data = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: 'Success',
            data,
        };
    }

    return {
        is_error: true,
        message: data?.Message ?? `Unknown error ${resp.status}`,
    };
};

export const makeBillPayment = async (params: {
    billType: string;
    client_account: string;
    bill: string;
    amount: number;
    bill_account: string;
    token: string;
    paybillNo?: string;
    saved_bill?: number;
}): Promise<StandardResponse<undefined>> => {
    const resp = await post('client-api/investments/pay-bill', {
        data: {
            bill_type: params.billType,
            number: params.client_account,
            bill: params.bill,
            amount: params.amount,
            bill_account: params.bill_account,
            token: params.token,
            paybill_number: params.paybillNo ?? '',
            saved_bill: params.saved_bill ?? '',
        },
    });

    const data = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: 'Success',
        };
    }

    return {
        is_error: true,
        message: data?.Message ?? `Unknown error ${resp.status}`,
    };
};

export const sendMoneyPresentment = async (params: {
    client_account: string;
    amount: number;
    phoneNumber: string;
    tokenMethod: string;
}): Promise<StandardResponse<FeesResponse>> => {
    const resp = await post('client-api/presentment/send-money', {
        data: {
            client_account: params.client_account,
            phone: params.phoneNumber,
            amount: params.amount,
            token_method: params.tokenMethod,
        },
    });

    const data = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: 'Success',
            data,
        };
    }

    return {
        is_error: true,
        message: data?.Message ?? `Unknown error ${resp.status}`,
    };
};

export const sendMoney = async (params: {
    client_account: string;
    amount: number;
    phoneNumber: string;
    token: string;
}): Promise<StandardResponse<undefined>> => {
    const resp = await post('client-api/investments/send-money', {
        data: {
            client_account: params.client_account,
            phone: params.phoneNumber,
            amount: params.amount,
            token: params.token,
        },
    });

    const data = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: 'Success',
        };
    }

    return {
        is_error: true,
        message: data?.Message ?? `Unknown error ${resp.status}`,
    };
};

export const unitTransfers = async (data: {
    sending_account: string;
    receiving_account: string;
    amount: number;
    token: string;
}): Promise<StandardResponse<undefined>> => {
    const resp = await post('client-api/investments/unit-transfers', {
        data,
    });

    const d = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: 'Success',
        };
    }

    return {
        is_error: true,
        message: d?.Message ?? `Unknown error ${resp.status}`,
    };
};

export const getBills = async (): Promise<
    StandardResponse<ReadonlyArray<Bill>>
> => {
    const resp = await get('client-api/investments/bills');

    const data = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: '',
            data,
        };
    }

    return {
        is_error: true,
        message: data?.Message ?? `Unknown error ${resp.status}`,
    };
};

export const getSavedBills = async (
    url?: string
): Promise<
    StandardResponse<{
        results: ReadonlyArray<SavedBill>;
        previous?: string;
        next?: string;
    }>
> => {
    const resp = await get(url ?? 'client-api/investments/saved-bills/');

    const data = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: '',
            data,
        };
    }

    return {
        is_error: true,
        message: data?.Message ?? `Unknown error ${resp.status}`,
    };
};
type NewBill = {
    bill_id?: number | string;
    name: string;
    account: string;
    provider?: string;
    bill_number?: string;
};
export const saveBillPayment = async (bill: NewBill) => {
    const resp = await post('client-api/investments/manage-saved-bills', {
        data: bill,
    });

    const data = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: '',
            data,
        };
    }

    return {
        is_error: true,
        message: data?.Message ?? `Unknown error ${resp.status}`,
    };
};

export const deleteBillPayment = async (id: string | number) => {
    const resp = await request(
        'client-api/investments/manage-saved-bills',
        'DELETE',
        { data: { id } }
    );

    const data = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: '',
            data,
        };
    }

    return {
        is_error: true,
        message: data?.Message ?? `Unknown error ${resp.status}`,
    };
};

type QueriedAccount = {
    number: string;
    name: string;
    active: boolean;
    product: string;
    product_short_name: string;
};

export const queryAccount = async (
    number: string,
    tokenMethod: string
): Promise<StandardResponse<QueriedAccount>> => {
    const resp = await post('client-api/investments/query-accounts', {
        data: { number, token_method: tokenMethod },
    });

    const data = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: '',
            data,
        };
    }

    return {
        is_error: true,
        message: data?.Message ?? `Unknown error ${resp.status}`,
    };
};

export const createWithdrawalPresentment = async (
    amount: number,
    number: string,
    bank_account_id: string,
    token_method: string
): Promise<StandardResponse<FeesResponse & { exchange_rate?: number }>> => {
    const resp = await post('client-api/presentment/withdrawal', {
        data: {
            amount,
            number,
            bank_account_id,
            token_method,
        },
    });

    const data = await resp.json();
    if (resp.ok) {
        return {
            is_error: false,
            message: 'Success',
            data,
        };
    }

    return {
        is_error: true,
        message: data?.Message ?? 'Unknown error',
    };
};

export const createWithdrawal = async (
    amount: number,
    number: string,
    bank_account_id: string,
    token: string
) => {
    const resp = await post('client-api/investments/withdraw', {
        data: {
            amount,
            number,
            bank_account_id,
            token,
        },
    });

    const data = await resp.json();
    if (resp.ok) {
        return data;
    }

    return {
        is_error: true,
        status_code: resp.status,
        message: data?.Message ?? 'Unknown error',
    };
};

export const FETCH_STATEMENT_URL = 'client-api/investments/statement';

export const fetchStatement = async (
    number: string,
    format: string,
    startDate?: Date | string,
    endDate?: Date | string
) => {
    const resp = await post(FETCH_STATEMENT_URL, {
        data: {
            number,
            format,
            start_date:
                typeof startDate === 'string' || !startDate
                    ? startDate ?? ''
                    : dateFormatIso(startDate),
            end_date:
                typeof endDate === 'string' || !endDate
                    ? endDate ?? ''
                    : dateFormatIso(endDate?.toJSON()),
        },
    });

    if (resp.ok) {
        const output = format === 'pdf' ? await resp.blob() : await resp.text();

        return {
            is_error: false,
            status_code: '',
            data: output,
            file_name: getFileNameFromResponse(resp),
        };
    }

    const data = await resp.json();

    return {
        is_error: true,
        status_code: resp.status,
        message: data?.Message ?? 'Unknown error',
    };
};

export const fetchActivity = async (
    account_number: string | undefined,
    per_page: number,
    url?: string,
    start_date?: Date,
    end_date?: Date
) => {
    const resp = await get(url ?? `client-api/transactions/`, {
        params: {
            account_number: account_number ? `${account_number}` : '',
            page_size: per_page,
            start_date: start_date ? dateFormatIso(start_date) : '',
            end_date: end_date ? dateFormatIso(end_date) : '',
        },
    });
    const data = await resp.json();
    if (!resp.ok) {
        return {
            is_error: true,
            status_code: resp.status,
            message: data?.Message ?? 'Unknown error',
        };
    }

    return {
        is_error: false,
        status_code: resp.status,
        message: 'Success',
        data,
    };
};

export const fetchApplication = async (
    code: string,
    key: string
): Promise<{ success: boolean; message: string; client?: Client }> => {
    const resp = await get(`clients/applications/${code}/${key}`);

    const data = await resp.json();

    if (resp.ok) {
        return {
            success: true,
            message: 'success',
            client: data,
        };
    }

    return {
        success: false,
        message: data.Message,
    };
};

type AppKycData = {
    data?: ApplicationKYCData;
    success: boolean;
    message: string;
};

export const fetchApplicationKYC = async (
    code: string,
    signature: string
): Promise<AppKycData> => {
    const resp = await get(
        `clients/applications/${code}/${signature}/kyc-requirements`
    );

    const data = await resp.json();
    if (resp.ok) {
        return {
            success: true,
            message: 'Success',
            data,
        };
    }

    return {
        success: false,
        message: data.Message,
    };
};

export const fetchProducts = async (
    dispatch?: AppStateDispatch
): Promise<Product[] | undefined> => {
    const resp = await get('client-api/products');
    const data = await resp.json();

    if (!resp.ok) {
        return undefined;
    }

    dispatch?.({
        type: 'SET_PRODUCTS',
        payload: data,
    });

    return data;
};

export const fetchFeatures = async (
    dispatch?: AppStateDispatch,
    skipCredentials?: boolean
): Promise<Feature[] | undefined> => {
    let withAuth = !skipCredentials;
    let resp = await get(`client-api/features/`, undefined, {
        skipAuth: skipCredentials,
    });

    if (!resp.ok) {
        // retry without auth for failures
        withAuth = false;
        resp = await get('client-api/features/', undefined, {
            skipAuth: true,
        });
    }

    if (!resp.ok) {
        return undefined;
    }

    const data: Feature[] = await resp.json();

    dispatch?.({
        type: 'SET_FEATURES',
        payload: {
            source: 'network',
            list: data,
            withAuth,
        },
    });

    const items: Record<Feature['name'], boolean> = {};

    data.forEach((feature) => {
        items[feature.name] = feature.enabled;
    });

    setItem('features', items, true);

    return data;
};

export const debouncedFetchFeatures = debounce(fetchFeatures, 1000, 'leading');

export const fetchSettings = async (
    dispatch?: AppStateDispatch,
    skipAuth?: boolean
): Promise<Settings | undefined> => {
    let withAuth = !skipAuth;
    let resp = await get('client-api/settings', undefined, {
        skipAuth,
    });

    if (!resp.ok) {
        withAuth = false;
        // retry request without auth
        resp = await get('client-api/settings', undefined, {
            skipAuth: true,
        });
    }

    if (!resp.ok) {
        return undefined;
    }

    const data = await resp.json();

    dispatch?.({
        type: 'SET_SETTINGS',
        payload: {
            withAuth,
            source: 'network',
            values: data,
        },
    });

    return data;
};

export const debouncedFetchSettings = debounce(fetchSettings, 1000, 'leading');

export const createAccount = async (
    amount: number,
    client_code: string,
    payment_method: string,
    product: string,
    label?: string,
    lock_in_period?: string
) => {
    const resp = await post('client-api/investments/create-account', {
        data: {
            amount,
            client: client_code,
            payment_method,
            product,
            alias: label ?? '',
            lock_in_period: lock_in_period ?? '',
        },
    });

    const data = await resp.json();

    if (!resp.ok) {
        return {
            success: false,
            message: data?.Message,
        };
    }

    return {
        success: true,
        message: '',
    };
};

export const fetchNextOfKin = async (
    client_code: string
): Promise<StandardResponse<NextOfKin[]>> => {
    const resp = await get('client-api/next-of-kin', {
        params: { code: client_code },
    });

    const data = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: '',
            data,
        };
    }

    return {
        is_error: true,
        message: data?.Message ?? `Unknown error - ${resp.status}`,
    };
};

type NextOfKinInput = {
    client_code: string;
} & NextOfKin;

export const createNextOfKin = async (
    nextOfKin: NextOfKinInput
): Promise<StandardResponse<never>> => {
    const resp = await post('client-api/next-of-kin', { data: nextOfKin });

    const data = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: '',
        };
    }

    return {
        is_error: true,
        message: data?.Message ?? `Unknown error - ${resp.status}`,
    };
};

export const fetchKycDocuments = async (
    client_code: string
): Promise<StandardResponse<KycDocument[]>> => {
    const resp = await get('client-api/kyc-documents', {
        params: { code: client_code },
    });

    const data = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: '',
            data,
        };
    }

    return {
        is_error: true,
        message: data?.Message ?? `Unknown error - ${resp.status}`,
    };
};
type FileResponse = {
    file?: Blob;
    name?: string;
};
export const viewDocument = async (
    client_code: string,
    document_id: string
): Promise<StandardResponse<FileResponse>> => {
    const resp = await get('client-api/kyc-documents/view', {
        params: { client_code, document_id },
    });

    if (resp.ok) {
        return {
            is_error: false,
            message: '',
            data: {
                file: await resp.blob(),
                name: getFileNameFromResponse(resp),
            },
        };
    }

    return {
        is_error: true,
        message:
            (await resp.json())?.Message ?? `Unknown error - ${resp.status}`,
    };
};

export const deleteKycDocument = async (document_id: string) => {
    const resp = await get('client-api/kyc-documents/delete', {
        params: { document_id },
    });

    return {
        is_error: !resp.ok,
        message:
            (await resp.json())?.Message ?? `Unknown error - ${resp.status}`,
    };
};

type Currencies = ReadonlyArray<Currency>;

export const fetchCurrencies = async (): Promise<
    StandardResponse<Currencies>
> => {
    const resp = await get('client-api/currencies');

    const data = await resp.json();

    if (resp.ok) {
        return {
            is_error: false,
            message: 'Success',
            data,
        };
    }
    return {
        is_error: true,
        message: 'Failed fetching currencies',
    };
};

export const requestToken = (token_method: string, reason?: string) => {
    return post('client-api/request-token', {
        data: { token_method, reason: reason ?? '' },
    });
};

type GetOtpArgs = {
    token?: string;
    linkToken?: string;
    username?: string;
    password?: string;
    encrypted?: boolean;
};

export const getOtpLink = async ({
    token,
    linkToken,
    username,
    password,
    encrypted,
}: GetOtpArgs): Promise<StandardResponse<{ link: string }>> => {
    const resp = await post(
        linkToken
            ? 'client-api/otp-setup-link-email'
            : 'client-api/otp-setup-link',
        {
            data: {
                token: token ?? '',
                linkToken: linkToken ?? '',
                username: username ?? '',
                password: password ?? '',
                encrypted: encrypted ?? false,
            },
        }
    );

    const data = await resp.json();

    if (data.success) {
        return {
            is_error: false,
            message: '',
            data: { link: data.link },
        };
    }

    return {
        is_error: true,
        message: data.Message,
    };
};
