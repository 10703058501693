import { refreshDocs } from '.';
import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
    deleteKycDocument,
    fetchClientBankAccounts,
    fetchKycDocuments,
    fetchNextOfKin,
    viewDocument,
} from '@etica-js/api/src/appState/actions';
import { useAppContext } from '@etica-js/api/src/appState/state';
import {
    ClientBankAccount,
    InvestmentState,
    KycDocument,
    NextOfKin,
} from '@etica-js/api/src/schema';
import { download } from '@etica-js/utils/src/download';

import { Confirmation } from '../../components/ui/confirmation';
import { LoadingAnimation } from '../../components/ui/loading';
import styles from './dashboard.module.scss';

export const ProfilePage = () => {
    const appCtx = useAppContext();
    const [client, setClient] = useState<InvestmentState[number] | undefined>(
        undefined
    );
    const [bankAccounts, setBankAccounts] = useState<ClientBankAccount[]>([]);
    const [nextsOfKin, setNextsOfKin] = useState<NextOfKin[]>([]);
    const [kycDocuments, setKycDocuments] = useState<KycDocument[]>([]);

    const [downloading, setDownloading] = useState(false);

    const selectClient = (code: string) => {
        appCtx.investments?.forEach((cl) => {
            if (cl.code.toString() === code.toString()) {
                setClient(cl);
            }
        });
    };

    const viewKycDocument = (document: KycDocument) => {
        setDownloading(true);
        viewDocument(client?.code ?? '', document.document_id)
            .then((doc) => {
                if (doc.is_error) {
                    toast.error('Could not download document');
                    return;
                }

                const file_name =
                    doc.data?.name ??
                    document.requirement.name ??
                    document.type;
                download(doc.data?.file ?? new Blob(), file_name);
            })
            .finally(() => setDownloading(false));
    };

    const fetchKycDocs = () => {
        fetchKycDocuments(client?.code ?? '').then((data) => {
            setKycDocuments(data.data ?? []);
        });
    };

    useEffect(() => {
        if (!client) {
            return;
        }

        fetchClientBankAccounts(client?.code).then((accounts) => {
            setBankAccounts(accounts);
        });
        fetchNextOfKin(client?.code ?? '').then((nexts) => {
            setNextsOfKin(nexts.data ?? []);
        });
        fetchKycDocs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client]);

    const deleteKycDoc = async (doc_id: string) => {
        const { is_error, message } = await deleteKycDocument(doc_id);
        refreshDocs(appCtx.dispatch);
        fetchKycDocs();
        if (is_error) {
            toast.error(message ?? 'Cannot delete document');
        } else {
            toast.success('Document deleted successfully');
        }
    };

    useEffect(() => {
        if (!client && appCtx.investments?.length) {
            setClient(appCtx.investments[0]);
        }
    }, [appCtx, client]);

    return (
        <div className="bg-white flex flex-wrap rounded px-2 py-2">
            <div className="w-full">
                <div className={`w-full pt-5 mb-5`}>
                    <label className="text-gray-700">Select client</label>
                    <select
                        className="border border-gray-400 rounded bg-gray-50 text-gray-600 px-5 py-4 ml-5"
                        onChange={(e) => selectClient(e.currentTarget.value)}
                    >
                        {appCtx.investments?.map((client) => (
                            <option key={client.code} value={client.code}>
                                {client.name} - {client.code}
                            </option>
                        ))}
                    </select>

                    <hr className="mt-5" />
                </div>

                {(appCtx.kycStatus?.count ?? 0) > 0 && (
                    <div className="w-full mb-5">
                        <h4 className="text-lg font-semibold">
                            MISSING DOCUMENTS
                        </h4>

                        <div className="font-sm">
                            <p className="mb-3 text-red-500">
                                Click on each of the names listed to upload the
                                required documents
                            </p>
                            <ol className="font-sm list-decimal text-gray-800 pl-8">
                                {appCtx.kycStatus?.kyc
                                    ?.filter((cl) => (cl.count || 0) > 0)
                                    ?.map((cl) => (
                                        <li key={cl.code}>
                                            <Link
                                                to={`/dashboard/profile/${cl?.code}/upload-kyc`}
                                            >
                                                {cl.name} - {cl.code} (
                                                <span className="text-red-500">
                                                    {cl.count} missing
                                                </span>
                                                )
                                            </Link>
                                        </li>
                                    ))}
                            </ol>
                        </div>
                    </div>
                )}
                <div className="w-full mb-5">
                    <h4 className="text-lg font-semibold">
                        TWO FACTOR AUTHENTICATION
                    </h4>

                    <div className="font-sm">
                        <p className="mb-3">
                            Click below to set up a two factor authentication
                            app (such as Google Authenticator or Twilio Authy)
                            that will enable you to get a login/transaction
                            token from your mobile phone.
                        </p>

                        <Link
                            className="small secondary button mb-5"
                            to={`/dashboard/profile/two-factor-auth`}
                        >
                            Set Up Two Factor Authentication
                        </Link>
                    </div>
                </div>
                <div className="w-full">
                    <h4 className="text-lg font-semibold">
                        BANK/MOBILE MONEY ACCOUNTS
                    </h4>
                    <div className="w-full">
                        <Link
                            className="small secondary button float-right mb-5"
                            to={`/dashboard/profile/${client?.code}/add-bank-account`}
                        >
                            Add Bank/Mobile Money Account
                        </Link>
                    </div>
                    <div className="w-full">
                        {!bankAccounts.length && (
                            <div className="mb-5">
                                No bank/mobile money accounts found!
                            </div>
                        )}

                        {bankAccounts.length > 0 && (
                            <div className="w-full mb-5 overflow-scroll">
                                <table className={styles.table + ` mt-5`}>
                                    <thead>
                                        <tr>
                                            <th>Account Name</th>
                                            <th>Account Number</th>
                                            <th>Bank</th>
                                            <th>Swift Code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bankAccounts.map((acc) => (
                                            <tr key={acc.id}>
                                                <td>{acc.name}</td>
                                                <td>{acc.number}</td>
                                                <td>{acc.bank}</td>
                                                <td>{acc.swift_code}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-full">
                    <h4 className="text-lg font-semibold">NEXT OF KIN</h4>
                    <div className="w-full">
                        <Link
                            className="small secondary button float-right mb-5"
                            to={`/dashboard/profile/${client?.code}/add-next-of-kin`}
                        >
                            Add next of kin
                        </Link>
                    </div>
                    <div className="w-full">
                        {!nextsOfKin.length && (
                            <div className="mb-5">No next of kin found!</div>
                        )}

                        {nextsOfKin.length > 0 && (
                            <div className="w-full mb-5 overflow-scroll">
                                <table className={styles.table + ` mt-5`}>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Relationship</th>
                                            <th>ID/Passport Number</th>
                                            <th>Percentage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {nextsOfKin.map((no) => (
                                            <tr
                                                key={`${no.name} - ${no.id_or_passport} - ${no.dob}`}
                                            >
                                                <td>{no.name}</td>
                                                <td>{no.relationship}</td>
                                                <td>{no.id_or_passport}</td>
                                                <td>{no.percentage}%</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-full">
                    <h4 className="text-lg font-semibold">KYC DOCUMENTS</h4>
                    <div className="w-full">
                        <Link
                            className="small secondary button float-right mb-5"
                            to={`/dashboard/profile/${client?.code}/upload-kyc`}
                        >
                            Upload KYC Documents
                        </Link>
                    </div>
                    <div className="w-full">
                        {!kycDocuments.length && (
                            <div className="mb-5">No KYC documents found!</div>
                        )}

                        {kycDocuments.length > 0 && (
                            <div className="w-full mb-5 overflow-scroll">
                                <table className={styles.table + ` mt-5`}>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Number</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {kycDocuments.map((kyc) => (
                                            <tr key={kyc.id}>
                                                <td>
                                                    {kyc.requirement.name ??
                                                        kyc.type}
                                                </td>
                                                <td className="font-sm">
                                                    {
                                                        kyc.requirement
                                                            .description
                                                    }
                                                </td>
                                                <td>{kyc.number}</td>
                                                <td>
                                                    {!kyc.approval_id && (
                                                        <Confirmation
                                                            btnClass="small secondary button mr-2"
                                                            btnText="Delete"
                                                            confirmationText="Are you sure you want to delete this document?"
                                                            confirmBtnAction={() =>
                                                                deleteKycDoc(
                                                                    kyc.id
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    <button
                                                        className="small secondary button"
                                                        onClick={(e) =>
                                                            viewKycDocument(kyc)
                                                        }
                                                    >
                                                        <LoadingAnimation
                                                            loading={
                                                                downloading
                                                            }
                                                        />{' '}
                                                        <ArrowDownOnSquareIcon className="w-5 inline" />{' '}
                                                        Download
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
