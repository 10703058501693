import {
    AdjustmentsHorizontalIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';

import { useAppContext } from '@etica-js/api/src/appState/state';
import Rect from '@etica-js/assets/src/images/rect.png';

import { Currency } from './formatting';
import styles from './widgets.module.scss';

type Investment = {
    name: string;
    code: string;
    number: string;
    currency: string;
    product: string;
    product_short_name: string;
    wallet_balance: number;
    market_value: number;
    rate?: number | null;
    daily_yield?: number | null;
    price: number | null;
    calculator: string;
    interest_term?: string | null;
    market_value_base_currency: number;
    alias: string;
    lock_in_period: string;
};

export const InvestmentsWidget = () => {
    const appCtx = useAppContext();
    const [invs, setInv] = useState<Investment[]>([]);

    const setOpenModal = (name: string, product: string) => {
        appCtx.dispatch({
            type: 'SET_OPEN_MODAL',
            payload: { name: '', target: '' },
        });
        appCtx.dispatch({
            type: 'SET_OPEN_MODAL',
            payload: { name, target: product },
        });
    };

    const Logo = () => {
        return (
            <div className="w-60">
                <img src={Rect} alt="Illustration" />
            </div>
        );
    };

    const prepareInvestments = React.useCallback(() => {
        const invs: Investment[] = [];
        appCtx.investments?.forEach((client) => {
            client.accounts.forEach((acc) => {
                invs.push({
                    name: client.name,
                    code: client.code,
                    number: acc.number,
                    product: acc.product.name,
                    product_short_name: acc.product.short_name,
                    currency: acc.product.currency.code,
                    market_value: acc.valuation.market_value,
                    wallet_balance: acc.valuation.wallet_balance,
                    price: acc.product.performance.price,
                    rate: acc.product.performance.spot?.gross_annual,
                    daily_yield: acc.product.performance.spot?.gross_daily,
                    calculator: acc.product.fund.calculator,
                    interest_term: acc.product.fund.interest_term,
                    market_value_base_currency:
                        acc.valuation.market_value_base_currency,
                    alias: acc.alias,
                    lock_in_period: acc.lock_in_period,
                });
            });
        });

        return invs.sort(
            (a, b) =>
                b.market_value_base_currency - a.market_value_base_currency
        );
    }, [appCtx]);

    useEffect(() => {
        setInv(prepareInvestments());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appCtx]);

    const scrollable = useRef<HTMLDivElement>(null);

    const scroll = (scrollOffset: number) => {
        if (scrollable.current) {
            scrollable.current.scrollLeft += scrollOffset;
        }
    };

    return (
        <div>
            <h3 className="text-xl font-bold mb-5">My Investments</h3>

            <div className={styles.myInvestment + ' flex '} ref={scrollable}>
                {invs.map((inv) => (
                    <div
                        key={inv.number}
                        className={
                            styles.myInvestmentCard +
                            ' bg-white shrink text-sm flex flex-wrap md:w-1/3 lg:w-1/4'
                        }
                    >
                        <div className="w-full">
                            <button
                                className={styles.editButton}
                                onClick={() =>
                                    setOpenModal('account_settings', inv.number)
                                }
                            >
                                <AdjustmentsHorizontalIcon />
                            </button>
                            <Logo />
                        </div>

                        <h4 className="font-bold w-full"> {inv.product}</h4>
                        <p className="w-full">
                            <span className="">
                                {inv.name} {inv.number}
                            </span>
                            {inv.alias && (
                                <span className={styles.label}>
                                    {inv.alias}
                                </span>
                            )}
                        </p>
                        <div className={styles.moreInfo}>
                            <table className="w-full">
                                <tbody>
                                    <tr
                                        style={{
                                            borderBottom:
                                                (Math.abs(inv.wallet_balance) ||
                                                    0) <= 0.5
                                                    ? '1px solid #666'
                                                    : undefined,
                                        }}
                                    >
                                        <td>Your Investment</td>
                                        <td className="font-semibold text-right">
                                            <Currency
                                                value={inv.market_value}
                                                currency={inv.currency}
                                            />
                                        </td>
                                    </tr>
                                    {(Math.abs(inv.wallet_balance) || 0) >=
                                        0.5 && (
                                        <>
                                            <tr>
                                                <td>Cash balance*</td>
                                                <td className="font-semibold text-right">
                                                    <Currency
                                                        value={
                                                            inv.wallet_balance
                                                        }
                                                        currency={inv.currency}
                                                    />
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    borderBottom:
                                                        '1px solid #666',
                                                }}
                                            >
                                                <td>Total</td>
                                                <td className="font-semibold text-right">
                                                    <Currency
                                                        value={
                                                            inv.wallet_balance +
                                                            inv.market_value
                                                        }
                                                        currency={inv.currency}
                                                    />
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {inv.price && inv.price !== 1 && (
                                        <tr>
                                            <td>Unit Price </td>
                                            <td className="font-semibold text-right">
                                                <Currency
                                                    value={inv.price}
                                                    currency={inv.currency}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {!!inv.daily_yield && (
                                        <tr>
                                            <td>
                                                Annualized Daily{' '}
                                                {inv.interest_term ?? 'Yield'}
                                            </td>
                                            <td className="font-semibold text-right">
                                                <Currency
                                                    value={inv.daily_yield ?? 0}
                                                    currency=""
                                                />
                                                %
                                            </td>
                                        </tr>
                                    )}
                                    {!!inv.rate && (
                                        <tr>
                                            <td>
                                                Effective Annual{' '}
                                                {inv.interest_term ?? 'Yield'}
                                            </td>
                                            <td className="font-semibold text-right">
                                                <Currency
                                                    value={inv.rate}
                                                    currency=""
                                                />
                                                %
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {(Math.abs(inv.wallet_balance) || 0) >= 0.5 && (
                                <span className="text-xs text-gray-600">
                                    * auto-invested next working day
                                </span>
                            )}
                        </div>
                        <div className="flex flex-wrap justify-end content-end mt-5 w-full">
                            {inv.calculator !== 'fixed_interest' && (
                                <button
                                    className="small secondary button mr-2"
                                    onClick={() =>
                                        setOpenModal('withdraw', inv.number)
                                    }
                                >
                                    Withdraw
                                </button>
                            )}
                            <button
                                className="small primary button"
                                onClick={() =>
                                    setOpenModal('topup', inv.number)
                                }
                            >
                                Top-up
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="justify-items-end">
                <button className="mr-5" onClick={() => scroll(-100)}>
                    <ChevronLeftIcon className="w-5" />
                </button>
                <button onClick={() => scroll(100)}>
                    <ChevronRightIcon className="w-5" />
                </button>
            </div>
        </div>
    );
};
