import { Confirmation, Stage } from '.';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import {
    queryAccount,
    unitTransfers,
} from '@etica-js/api/src/appState/actions';
import { useAppContext } from '@etica-js/api/src/appState/state';

import form from '../../assets/styles.module.scss';
import { Aside, AsideProps } from '../ui/aside';
import { LoadingAnimation } from '../ui/loading';
import { currencyFormat } from '../widgets/formatting';
import { TokenSelector } from './parts/token';

type Props = {
    onClose: AsideProps['onClose'];
};

export const Transfer: React.FC<Props> = (props) => {
    const appCtx = useAppContext();
    const [amount, setAmount] = useState(0);
    const [sendingAccountNumber, setSendingAccountNumber] = useState('');
    const [receivingAccountNumber, setReceivingAccountNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const [tokenMethod, setTokenMethod] = useState('sms');
    const [token, setToken] = useState('');
    const [stage, setStage] = useState<Stage>(undefined);

    const getAccount = useCallback(() => {
        return appCtx.process('GET_ACCOUNTS');
    }, [appCtx]);

    const presentment = () => {
        setLoading(true);

        queryAccount(receivingAccountNumber, tokenMethod)
            .then((res) => {
                if (res.is_error) {
                    toast.error(res.message);
                    return;
                }

                setStage({
                    type: 'confirmation',
                    message: 'Confirm your transfer details below',
                    setToken,
                    tokenMethod,
                    data: [
                        {
                            label: 'From Account',
                            value: sendingAccountNumber,
                        },
                        {
                            label: 'To Account',
                            value: `${res.data?.name} (${res.data?.product})`,
                        },
                        {
                            label: 'Amount',
                            value: currencyFormat({ value: amount }),
                        },
                    ],
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const submit = (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        if (!stage) {
            presentment();
            return;
        }

        unitTransfers({
            sending_account: sendingAccountNumber,
            receiving_account: receivingAccountNumber,
            amount,
            token,
        })
            .then((result) => {
                appCtx.logger?.logUserAction('transfer', !result.is_error);
                if (result.is_error) {
                    toast.error('Failed - ' + result.message);
                    return;
                }
                toast.success('Saved successfully');
                setComplete(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Aside onClose={props.onClose} success={complete}>
            <div className="flex flex-wrap p-3">
                <h3 className="text-xl font-bold mb-5 mt-10">
                    Account Transfers
                </h3>

                <p className={form.infoBox}>
                    You can transfer between your own accounts, or to other
                    clients using their client code{' '}
                </p>

                {stage && stage.type === 'confirmation' && (
                    <Confirmation {...stage} />
                )}

                <form onSubmit={submit} className="w-full">
                    <div className="w-full">
                        {!stage && (
                            <>
                                <div className={form.fieldset}>
                                    <label htmlFor="">From Account</label>
                                    <select
                                        onChange={(e) =>
                                            setSendingAccountNumber(
                                                e.currentTarget.value
                                            )
                                        }
                                        required
                                    >
                                        <option value="">SELECT ACCOUNT</option>
                                        {getAccount()?.map((acc) => (
                                            <option
                                                key={acc.number}
                                                value={acc.number}
                                            >
                                                {acc.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className={form.fieldset}>
                                    <label htmlFor="">Amount</label>
                                    <input
                                        type="number"
                                        onChange={(e) =>
                                            setAmount(
                                                parseFloat(
                                                    e.currentTarget.value
                                                )
                                            )
                                        }
                                        required
                                    />
                                </div>
                                <div className={form.fieldset}>
                                    <label htmlFor="">Client code</label>
                                    <input
                                        type="text"
                                        onChange={(e) =>
                                            setReceivingAccountNumber(
                                                e.currentTarget.value
                                            )
                                        }
                                        required
                                    />
                                </div>
                                <TokenSelector
                                    onTokenMethodChange={setTokenMethod}
                                />
                            </>
                        )}
                        <div className={form.fieldset}>
                            {stage && stage.type === 'confirmation' && (
                                <button
                                    className="secondary button w-full mb-2"
                                    onClick={() => setStage(undefined)}
                                >
                                    Back
                                </button>
                            )}
                            <button
                                type="submit"
                                className="primary button w-full"
                            >
                                <LoadingAnimation loading={loading} />{' '}
                                {stage ? 'Confirm' : 'Send Token'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Aside>
    );
};
